.topStories {
  margin: 50px auto 0;
  width: 94%;
  font-family: 'graphik', Arial, sans-serif;

  .heading {
    color: white;
    font-style: italic;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2em;
    margin: 0.67em 0;
  }

  .container {
    .loading {
      background-color: white;
    }

    .videosContainer {
      padding: 0;
      list-style: none;
    }
  }
}

@media (max-width: 768px) {
  :global(.mol-mobile) {
    .topStories {
      margin: 25px auto 0;
      width: 100%;

      .heading {
        font-size: 1.5em !important;
      }
    }
  }
}
