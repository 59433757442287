.watchLatestShow {
  margin: 20px auto 0;
  width: 80%;

  :global(.gamma) & {
    width: 727px;
  }

  .header {
    color: white;
    display: flex;

    .headerLeft {
      flex: 65%;

      .heading {
        font-weight: normal;
        text-transform: uppercase;
        font-family: 'Graphik-Regular', Arial, sans-serif;
        font-size: 33px;
        margin: 0.67em 0;
      }
    }

    .headerRight {
      flex: 35%;
      text-align: right;
      padding-top: 9px;
    }
  }
}

@media (max-width: 768px) {
  :global(.mol-mobile) {
    .watchLatestShow {
      margin: 18px auto 0;
      width: 100%;

      .header {
        display: block;

        .headerLeft {
          .heading {
            font-size: 1.5em;
          }
        }

        .headerRight {
          text-align: left;
          padding-top: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}
