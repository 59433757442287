.videoThumb {
  background-color: white;
  border-radius: 10px;
  display: inline-flex;
  width: 48%;
  margin-bottom: 20px;
  overflow: hidden;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
  position: relative;
  background-image: url('./img/bg.jpg');
  cursor: pointer;
  max-height: 121px;

  .postThumb {
    position: relative;
    float: left;

    img {
      display: block;
      max-width: 220px;
      max-height: 121px;
    }

    .duration {
      position: absolute;
      bottom: 5px;
      right: 5px;
      color: white;
      background-color: black;
      font-family: Graphik, Arial, sans-serif;
      font-size: 12px;
      padding: 3px 6px;
    }
  }

  .postContent {
    padding: 0 0.9em;
    display: flex;
    align-items: center;

    .heading {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5; /* number of lines to show */
      line-height: 16px; /* fallback */
      max-height: 80px; /* 16*5 fallback */
      text-align: left;
      color: #0a50b0;
      font-family: Graphik, Arial, sans-serif;
      font-weight: normal;
      font-size: 1.2em;
      font-style: italic;
      word-break: break-word;

      .headline1 {
        color: #de2148;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}

.videoThumb:hover::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '\A';
  color: rgba(0, 0, 0, 0);
  background-color: rgba(255, 255, 255, 0.25);
}

.videoThumb:nth-child(odd) {
  margin-right: 2.8%;
}

@media (max-width: 768px) {
  :global(.mol-mobile) {
    .videoThumb {
      width: 100%;
      margin-bottom: 12px;

      .postThumb {
        img {
          max-width: 145px;
          max-height: 81px;
        }
      }

      .postContent {
        padding: 0 0.9em;
        display: flex;
        align-items: center;

        .heading {
          font-size: 1.1em;
          -webkit-line-clamp: 4;
          line-height: 16px;
          max-height: 64px;
        }
      }
    }
  }
}
