.channelPage {
  padding: 20px;
  width: 100%;
  max-width: 964px;
  margin: 0 auto 30px;
  border-radius: 0 0 10px 10px;
  background: #8bb3f8;
  background-image: url('./img/bg.jpg'); /* fallback */
  background-image: url('./img/bg.jpg'), linear-gradient(#002373, #8bb3f8 1092px);
  background-size: 100%;
  background-repeat: no-repeat;
  font-size: 13px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  :global(.mol-mobile) {
    .channelPage {
      width: 100%;
      padding: 10px;
      background-size: cover;
    }

    :global {
      .react-select__control {
        width: 100%;
        height: 38px;
      }

      .vjs-video-container {
        position: relative;
        width: 100%;

        video {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100% !important;
          height: 100% !important;
        }

        &::before {
          content: '';
          display: block;
          padding-top: 56.25%;
          padding-bottom: 86px;
        }
      }
    }
  }
}
